import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { clearAuthErrors, loginFailure, setRequestingCode } from '../actions';
import { updateUser } from '../../user/actions';
import axios from 'axios';

export const asyncWelcomeUser = (altMethod?: string) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      // POST to /self-register to check for user's existence
      // /self-register will create user, if the user doesn't exist
      const { loginFlow } = getState().auth;
      const { phoneNumber, email } = loginFlow;
      const firstName = 'Customer';
      let url = '/api/v1/self-register?requestCode=1';
      if (altMethod) url += `&altMethod=${altMethod}`;

      dispatch(clearAuthErrors());
      dispatch(setRequestingCode(true));

      let payload: any = { phoneNumber, firstName, app: 'web-ui' };
      if (altMethod === 'email') payload.email = email;

      const response = await backend.post(url, payload);

      if (response.status === 200) {
        dispatch(updateUser(response.data));
        dispatch(setRequestingCode(false));
      }
    } catch (error: any) {
      // Handle error response
      if (axios.isAxiosError(error) && error.response) {
        // Check for 404 status
        if (error.response.status === 404) {
          const message = altMethod === 'email'
            ? `No account with this email exists. Please register or sign in with your phone number first.`
            : 'User not found. Please try again later.';
          dispatch(loginFailure(message));
        } else {
          dispatch(loginFailure('User could not be welcomed. Please try again later.'));
        }
      } else {
        // Catch other errors
        console.error('User could not be welcomed. Please try again later.');
        dispatch(loginFailure('User could not be welcomed. Please try again later.'));
      }

      dispatch(setRequestingCode(false));
    }
  };
};
