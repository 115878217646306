import { LocationState, LocationActionTypes } from "./types";

const initialState: LocationState = {
  errors: {},
  selectedLocation: null,
  locations: [],
  locationFlow: {
    _id: null,
    userId: null,
    name: 'Home',
    address: '',
    query: '',
    instruction: '',
    queryResults: [],
    latitude: null,
    longitude: null,
    mode: 'create'
  },
  geolocatedCoordinates: {
    latitude: null,
    longitude: null
  },
  locationModals: {
    createLocationModalOpen: false,
  },
  loading: false
};

const locationReducer = (state = initialState, action: LocationActionTypes): LocationState => {
  switch (action.type) {
    case 'GET_USER_LOCATIONS_SUCCESS':
      return {
        ...state,
        locations: action.payload
      };
    case 'GET_USER_LOCATIONS_FAILURE':
      return {
        ...state,
        errors: { getLocations: action.payload }
      };
    case 'SELECT_LOCATION':
      return {
        ...state,
        selectedLocation: action.payload
      };
    case 'SET_USER_LOCATION_INPUTS':
      return {
        ...state,
        locationFlow: action.payload
      };
    case 'CREATE_LOCATION_SUCCESS':
      return {
        ...state,
        selectedLocation: action.payload,
        locations: [...state.locations, action.payload]
      };
    case 'CREATE_LOCATION_FAILURE':
      return {
        ...state,
        errors: { createLocation: action.payload }
      };
    case 'TOGGLE_CREATE_LOCATION_MODAL':
      return {
        ...state,
        locationModals: {
          ...state.locationModals,
          createLocationModalOpen: !state.locationModals.createLocationModalOpen,
        },
      };
    case 'EDIT_SELECTED_LOCATION_SUCCESS':
      return {
        ...state,
        selectedLocation: action.payload,
      };
    case 'EDIT_SELECTED_LOCATION_FAILURE':
      return {
        ...state,
        errors: { editSelectedLocation: action.payload }
      };
    case 'SET_GEOLOCATED_COORDINATES':
      return {
        ...state,
        geolocatedCoordinates: action.payload
      };
    case 'SET_LOCATIONS_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    default:
      return state;
  }
};

export default locationReducer;