import { AuthActionTypes, UserLoginFlow, ActiveLogin, UserActivityEvent, UserUpdateEmailFlow } from './types';

export const loginSuccess = (loginToken: string ): AuthActionTypes => ({
  type: 'LOGIN_SUCCESS',
  payload: { loginToken },
});

export const loginFailure = (error: string): AuthActionTypes => ({
  type: 'LOGIN_FAILURE',
  payload: error,
});

export const logout = (): AuthActionTypes => ({
  type: 'LOGOUT',
});

export const logoutFailure = (error: string): AuthActionTypes => ({
  type: 'LOGOUT_FAILURE',
  payload: error,
});

export const setRequestingCode = (payload: boolean): AuthActionTypes => ({
  type: 'SET_REQUESTING_CODE',
  payload
})

export const setCheckingCode = (payload: boolean): AuthActionTypes => ({
  type: 'SET_CHECKING_CODE',
  payload
})

export const setLoginInputs = (inputs: UserLoginFlow): AuthActionTypes => ({
  type: 'SET_LOGIN_INPUTS',
  payload: inputs
})

export const clearAuthErrors = (): AuthActionTypes => ({
  type: 'CLEAR_AUTH_ERRORS',
})

export const getActiveLoginsSuccess = (activeLogins: ActiveLogin[]): AuthActionTypes => ({
  type: 'GET_ACTIVE_LOGINS_SUCCESS',
  payload: activeLogins,
});

export const getActiveLoginsFailure = (error: string): AuthActionTypes => ({
  type: 'GET_ACTIVE_LOGINS_FAILURE',
  payload: error,
});

export const getUserEventsSuccess = (userEvents: UserActivityEvent[]): AuthActionTypes => ({
  type: 'GET_USER_EVENTS_SUCCESS',
  payload: userEvents,
});

export const getUserEventsFailure = (error: string): AuthActionTypes => ({
  type: 'GET_USER_EVENTS_FAILURE',
  payload: error,
});

export const setGettingActiveLogins = (payload: boolean): AuthActionTypes => ({
  type: 'SET_GETTING_ACTIVE_LOGINS',
  payload,
});

export const setGettingUserEvents = (payload: boolean): AuthActionTypes => ({
  type: 'SET_GETTING_USER_EVENTS',
  payload,
});


export const setUpdateEmailInputs = (inputs: UserUpdateEmailFlow): AuthActionTypes => ({
  type: 'SET_UPDATE_EMAIL_INPUTS',
  payload: inputs
})