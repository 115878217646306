import { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import { Flex } from "@chakra-ui/react";

const Router = () => {

  const Account = lazy(() => import("../pages/Account"));
  
  return (
    <Suspense fallback={null}>
      <div style={{ background: "linear-gradient(90deg, #FFF5F5, #F7FAFC, #FFFAF0)"}}>
        <Styles />
        <Header />
        <Flex flexDirection={'column'} justifyContent={'space-between'}>
          <Routes>
            {routes.map((routeItem) => {
              const Component = lazy(() => import(`../pages/${routeItem.component}`));
              if (routeItem.redirect) {
                return (
                  <Route
                    key={routeItem.path}
                    path={routeItem.path}
                    element={<Navigate to={routeItem.redirect} replace={false} />}
                  />
                );
              }

              return (
                <Route
                  key={routeItem.path}
                  path={routeItem.path}
                  element={<Component />}
                />
              );
            })}
          </Routes>
        </Flex>
        <Footer />
      </div>
    </Suspense>
  );
};

export default Router;
