import { PageState, PageActionTypes } from "./types";

const initialState: PageState = {
  loading: true,
  errors: {},
  selectedPage: null,
  selectedOrderItem: null,
  pageOrderConfirmation: {
    orderNumber: null,
    status: '',
    currency: 'JMD',
    orderRevenue: 0
  },
  otherItemsFromBusiness: [],
  previouslyViewedItems: [],
  previouslyPurchasedItems: [],
  selectedOrderTransactions: [],
  trendingItems: [],
  pageOrderFlow: {
    userId: null,
    sourceType: 'businessLocation',
    sourceId: null,
    destinationType: 'userLocation',
    destinationId: null,
    distance: 0,
    tip: 0,
    deliveryFee: 0,
    deliveryCommission: 300,
    valueFee: 0,
    tax: 0,
    rounding: 0,
    orderRevenue: 0,
    orderExpenses: 0,
    creatorId: null,
    status: 'created',
    discountAmount: 0,
    discountDescription: '',
    pointsEarned: 0,
    pickupInstruction: '',
    deliveryInstruction: '',
    deliveryType: 'out of range',
    originBranchCode: '',
    pagesTransactionFee: 0,
    branchCode: '',
    channel: 'pages-ui',
    currency: '',
    calculatingDeliveryFee: false,
    creatingOrder: false,
    data: {
      ratingDetails: '',
      sourceLatitude: null,
      sourceLongitude: null,
      destinationLatitude: null,
      destinationLongitude: null,
      purchaseMethods: [],
      paymentMethods: [],
      orderItems: []
    },
    pricing: {
      baseDeliveryRate: 690,
      baseDeliveryRangeKM: 2,
      perKMDeliveryRate: 70,
      finalDeliveryFeeRounding: 10,
      baseDeliveryCommission: 300,
      baseDeliveryCommissionRangeKM: 5,
      perKMDeliveryCommissionRate: 0,
      valueFeeThreshold: 5000,
      valueFeePercentageRate: 0.1,
      pagesTransactionFeePercentageRate: 0.075,
      finalRevenueRounding: 10,
      taxes: [{
        taxName: 'GCT',
        taxThresholdMin: 0,
        taxThresholdMax: null
      }]
    },
    acceptedAt: null,
    errors: {},
    source: null,
    destination: null,
    targetDeliveryTime: null
  },
  soldOutEstimate: {
    itemId: '',
    sales: 0,
    days: 0,
    soldOutTimeEstimateMs: ''
  },
  queriedItems: [],
  itemsQuery: {
    currentPage: null,
    itemsPerPage: null,
    totalPages: null,
    totalItems: null,
    search: '',
    freeDelivery: false
  },
  isQuerying: false
};

const pageReducer = (state = initialState, action: PageActionTypes): PageState => {
  switch (action.type) {
    case 'SET_PAGE_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'SELECT_PAGE':
      return {
        ...state,
        selectedPage: action.payload,
      };
    case 'UPDATE_PAGE_ORDER_INPUTS':
      return {
        ...state,
        pageOrderFlow: action.payload
      };
    case 'CREATE_ORDER_SUCCESS':
      return {
        ...state,
        pageOrderFlow: initialState.pageOrderFlow,
        pageOrderConfirmation: action.payload
      };
    case 'CREATE_ORDER_FAILURE':
      return {
        ...state,
        errors: { createOrder: action.payload }
      };
    case 'SELECT_ORDER_ITEM':
      return {
        ...state,
        selectedOrderItem: action.payload,
      };
    case 'UPDATE_ORDER_ITEM':
      return {
        ...state,
        selectedOrderItem: action.payload
      };
    case 'GET_ORDER_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        selectedOrderTransactions: action.payload
      };
    case 'GET_ORDER_TRANSACTIONS_FAILURE':
      return {
        ...state,
        errors: { getOrderTransactions: action.payload }
      };
    case 'GET_OTHER_ITEMS_FROM_BUSINESS_SUCCESS':
      return {
        ...state,
        otherItemsFromBusiness: action.payload
      };
    case 'GET_OTHER_ITEMS_FROM_BUSINESS_FAILURE':
      return {
        ...state,
        errors: { getOtherItemsFromBusiness: action.payload }
      };
    case 'GET_PREVIOUSLY_VIEWED_ITEMS_SUCCESS':
      return {
        ...state,
        previouslyViewedItems: action.payload
      };
    case 'GET_PREVIOUSLY_VIEWED_ITEMS_FAILURE':
      return {
        ...state,
        errors: { getPreviouslyViewedItems: action.payload }
      };
    case 'GET_PREVIOUSLY_PURCHASED_ITEMS_SUCCESS':
      return {
        ...state,
        previouslyPurchasedItems: action.payload
      };
    case 'GET_PREVIOUSLY_PURCHASED_ITEMS_FAILURE':
      return {
        ...state,
        errors: { getPreviouslyPurchasedItems: action.payload }
      };
    case 'GET_TRENDING_ITEMS_SUCCESS':
      return {
        ...state,
        trendingItems: action.payload
      };
    case 'GET_TRENDING_ITEMS_FAILURE':
      return {
        ...state,
        errors: { getTrendingItems: action.payload }
      };
    case 'GET_ITEM_SOLD_OUT_ESTIMATE_SUCCESS':
      return {
        ...state,
        soldOutEstimate: action.payload
      };
    case 'GET_ITEM_SOLD_OUT_ESTIMATE_FAILURE':
      return {
        ...state,
        errors: { soldOutEstimate: action.payload }
      };
    case 'UPDATE_ITEMS_QUERY':
      return {
        ...state,
        itemsQuery: action.payload
      };
    case 'GET_QUERIED_ITEMS_SUCCESS':
      return {
        ...state,
        queriedItems: action.payload
      };
    case 'GET_QUERIED_ITEMS_FAILURE':
      return {
        ...state,
        errors: { getQueriedItems: action.payload }
      };
    case 'SET_ITEMS_QUERYING':
      return {
        ...state,
        isQuerying: action.payload
      }
    default:
      return state;
  }
};

export default pageReducer;
