import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { Cart } from '../types';
import { updateCartSuccess, updateCartFailure } from '../actions';
import { checkCartItems } from './checkCartItems';

export const asyncRemoveItemFromCart = (cartItemId: string) => {
  return async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    try {
      const { auth, cart } = getState();
      const { loginToken } = auth;
      const cartId = cart.cart?._id;
      const { itemReferences } = getState().cart;

      if (!loginToken) {
        throw new Error('User not authenticated');
      }

      const headers = {
        'X-Auth-Token': loginToken,
      };

      const response = await backend.delete(`/commerce-service/api/v1/carts/${cartId}/items/${cartItemId}?returnCart=1`, { headers });

      if (response.status === 200) {
        const updatedCart: Cart = response.data;
        const newCart = checkCartItems(updatedCart, itemReferences)
        dispatch(updateCartSuccess(newCart));
      } else {
        dispatch(updateCartFailure('Failed to remove item from cart.'));
      }
    } catch (error) {
      console.error('Failed to remove item from cart:', error);
      dispatch(updateCartFailure('An error occurred while removing item from cart.'));
    }
  };
};
