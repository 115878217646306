import { WalletsState, WalletsActionTypes } from './types';

const initialState: WalletsState = {
  userWallets: [],
  isLoadingWallets: false,
  selectedWallet: null,
  selectedWalletTransactions: [],
  selectedWalletTransactionsDetails: {
    totalItems: 0,
    currentPage: 1,
    totalPages: 1,
  },
  topUpConfigs: {
    baseCurrency: 'JMD',
    targetCurrency: 'JMD',
    marketRate: 1,
    markupRate: 1,
    jmdTopUps: [],
    nonJmdTopUps: [],
    marketRateCheckedAt: null,
    marketRateEffectiveAt: null,
    recurringBonus: {
      percent: 0,
      waitTimeDays: 365
    }
  },
  targetWallet: null,
  isSearchingTargetWallet: false,
  stripePaymentFlow: {
    baseCurrency: 'JMD',
    targetCurrency: 'JMD',
    baseAmount: 0,
    targetAmount: 0,
    markupExchangeRate: 1,
    marketExchangeRate: 1,
    marketRateEffectiveAt: null,
    marketRateCheckedAt: null,
    recurringTopUp: false,
    recurringTopUpFrequency: 'monthly',
    type: 'single',
    userId: null,
    destinationWalletId: null,
    volumeBonusPercent: 0,
    recurringBonusPercent: 0,
  },
  isLoadingTopUpConfigs: false,
  errors: null,
  isLoadingStripePaymentIntent: false,
  stripeClientSecret: null,
  transferConfirmation: null,
  isTransferringFunds: false
};

const walletsReducer = (state = initialState, action: WalletsActionTypes): WalletsState => {
  switch (action.type) {
    case 'SET_WALLETS_LOADING':
      return {
        ...state,
        isLoadingWallets: action.payload,
      };
    case 'GET_USER_WALLETS_SUCCESS':
      return {
        ...state,
        userWallets: action.payload,
        isLoadingWallets: false,
      };

    case 'GET_USER_WALLETS_FAILURE':
      return {
        ...state,
        isLoadingWallets: false,
        errors: action.payload,
      };

    case 'SELECT_WALLET':
      return {
        ...state,
        selectedWallet: action.payload
      };

    case 'SET_WALLET_TRANSACTIONS_LOADING':
      return {
        ...state,
        selectedWalletTransactions: [],
        errors: null,
      };

    case 'GET_SELECTED_WALLET_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        selectedWalletTransactions: action.payload.transactions,
        selectedWalletTransactionsDetails: action.payload.details,
        isLoadingWallets: false,
      };

    case 'GET_SELECTED_WALLET_TRANSACTIONS_FAILURE':
      return {
        ...state,
        selectedWalletTransactions: [],
        errors: action.payload,
      };
    case 'UPDATE_WALLET_TOP_UP_CONFIGS':
      return {
        ...state,
        topUpConfigs: action.payload,
        isLoadingTopUpConfigs: false, 
      };
    case 'SET_WALLET_TOP_UP_CONFIGS_LOADING':
      return {
        ...state,
        isLoadingTopUpConfigs: action.payload,
      };
    case 'UPDATE_WALLET_STRIPE_PAYMENT_FLOW':
      return {
        ...state,
        stripePaymentFlow: action.payload
      };
    case 'RESET_WALLET_STRIPE_PAYMENT_FLOW':
      return {
        ...state,
        stripePaymentFlow: initialState.stripePaymentFlow
      }
    case 'STRIPE_PAYMENT_INTENT_LOADING':
      return {
        ...state,
        isLoadingStripePaymentIntent: action.payload,
      };
    case 'STRIPE_PAYMENT_INTENT_SUCCESS':
      return {
        ...state,
        stripeClientSecret: action.payload,
        errors: null,
      };
    case 'STRIPE_PAYMENT_INTENT_FAILURE':
      return {
        ...state,
        errors: action.payload,
      };
    case 'SELECT_TARGET_WALLET_SUCCESS':
      return {
        ...state,
        targetWallet: action.payload,
        errors: {...state.errors, selectTargetWallet: undefined},
      };
    case 'SELECT_TARGET_WALLET_FAILURE':
      return {
        ...state,
        targetWallet: null,
        errors: {...state.errors, selectTargetWallet: action.payload},
      };
    case 'SET_TARGET_WALLET_LOADING':
      return {
        ...state,
        isSearchingTargetWallet: action.payload
      }
    case 'SET_TRANSFER_LOADING':
      return {
        ...state,
        isTransferringFunds: action.payload,
      };
    case 'UPDATE_TRANSFER_CONFIRMATION':
      return {
        ...state,
        transferConfirmation: action.payload,
      };
    default:
      return state;
  }
};

export default walletsReducer;
