import { EventActionTypes, EventState } from "./types";

const initialState: EventState = {
  errors: {},
  lastHeartbeatTime: null,
  eventFlow: {
    businessId: null,
    itemId: null,
    userId: null,
    sessionId: null,
    event: 'page-view',
    app: 'pages-ui',
    data: {}
  }
}

const eventReducer = (state = initialState, action: EventActionTypes): EventState => {
  switch (action.type) {
    case 'EVENT_FLOW_UPDATE':
      return {
        ...state,
        eventFlow: action.payload
      };
    case 'EVENT_FLOW_ERROR':
      return {
        ...state,
        errors: { eventFlowError: action.payload }
      };
    case 'EVENT_FLOW_HEARTBEAT':
      return {
        ...state,
        lastHeartbeatTime: action.payload
      };
    default:
      return state;
  }
}

export default eventReducer;