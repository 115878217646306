import { Dispatch } from 'redux';

import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { setCheckingCode, setUpdateEmailInputs } from '../actions';
import { updateUser } from '../../user/actions';
import { 
  osName, osVersion, browserName, browserVersion, 
  mobileVendor, mobileModel, deviceType 
} from 'react-device-detect';
import axios from 'axios';

export const asyncCompleteEmailVerification = () => {
  return async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const { user } = getState().user;
    const { updateEmailFlow } = getState().auth;
    const { verificationCode } = updateEmailFlow;
    try {
      let location: { latitude: number | null, longitude: number | null } = { latitude: null, longitude: null }
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            location = { latitude, longitude };
          },
          (error) => {
            console.error('Error getting location:', error.message);
          }
        );
      }
      dispatch(setCheckingCode(true));
      const response = await backend.post(`/api/v1/complete-email-verification`, 
      { 
        userId: user?._id, 
        verificationCode, 
        app: 'web-ui',
        data: {
          osName, osVersion, browserName, browserVersion, 
          mobileVendor, mobileModel, deviceType, location        
        } 
      });
      if (response.status === 200) {
        const { user } = response.data;
        dispatch(updateUser(user));
        dispatch(setCheckingCode(false));
        dispatch(setUpdateEmailInputs({...updateEmailFlow, errors: null, verificationCode: '', email: '', codeRequested: false}))
      } else {
        dispatch(setCheckingCode(false));
        dispatch(setUpdateEmailInputs({...updateEmailFlow, errors: {updateEmail: 'Unable to complete email verification. Try again.'} }))
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response && error.response.data && error.response.data.message) {
        dispatch(setUpdateEmailInputs({...updateEmailFlow, errors: { updateEmail: error.response.data.message }}))
      } else {
        // Catch other errors
        dispatch(setUpdateEmailInputs({...updateEmailFlow, errors: { updateEmail: 'Error requesting verification code. Try again later.' }}))
        dispatch(setCheckingCode(false));
      }
      dispatch(setCheckingCode(false));
    }
  };
};