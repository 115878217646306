import { AuthState, AuthActionTypes } from './types';

const initialState: AuthState = {
  errors: {},
  loginFlow: {
    rawPhoneNumber: '+1876',
    phoneNumber: '',
    email: '',
    verificationCode: '',
    errors: {},
  },
  updateEmailFlow: {
    email: '',
    errors: {},
    verificationCode: '',
    codeRequested: false
  },
  activeLogins: [],
  userEvents: [],
  loginToken: null,
  isLoggedIn: false,
  isCheckingCode: false,
  isRequestingCode: false,
  isGettingActiveLogins: false,
  isGettingUserEvents: false
};

const authReducer = (state = initialState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case 'SET_LOGIN_INPUTS':
      return {
        ...state,
        loginFlow: action.payload
      };
    case 'CLEAR_AUTH_ERRORS':
      return {
        ...state,
        errors: {}
      };
    case 'SET_REQUESTING_CODE':
      return {
        ...state,
        isRequestingCode: action.payload
      };
    case 'SET_CHECKING_CODE':
      return {
        ...state,
        isCheckingCode: action.payload
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        loginToken: action.payload.loginToken,
        isLoggedIn: true,
        errors: {},
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        loginToken: null,
        isLoggedIn: false,
        loginFlow: { ...state.loginFlow, verificationCode: ''},
        errors: { login: action.payload },
      };
    case 'LOGOUT':
      return {
        ...state,
        loginFlow: initialState.loginFlow,
        loginToken: null,
        isLoggedIn: false,
        errors: {},
      };
    case 'LOGOUT_FAILURE':
      return {
        ...state,
        errors: {logout: action.payload},
      };
    case 'GET_ACTIVE_LOGINS_SUCCESS':
      return {
        ...state,
        activeLogins: action.payload,
        isGettingActiveLogins: false,
        errors: {},
      };

    case 'GET_ACTIVE_LOGINS_FAILURE':
      return {
        ...state,
        activeLogins: [],
        isGettingActiveLogins: false,
        errors: { activeLogins: action.payload },
      };

    case 'GET_USER_EVENTS_SUCCESS':
      return {
        ...state,
        userEvents: action.payload,
        isGettingUserEvents: false,
        errors: {},
      };

    case 'GET_USER_EVENTS_FAILURE':
      return {
        ...state,
        userEvents: [],
        isGettingUserEvents: false,
        errors: { userEvents: action.payload },
      };

    case 'SET_GETTING_ACTIVE_LOGINS':
      return {
        ...state,
        isGettingActiveLogins: action.payload,
      };

    case 'SET_GETTING_USER_EVENTS':
      return {
        ...state,
        isGettingUserEvents: action.payload,
      };
    case 'SET_UPDATE_EMAIL_INPUTS':
      return {
        ...state,
        updateEmailFlow: action.payload
      }
    default:
      return state;
  }
};

export default authReducer;
