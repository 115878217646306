import { UpdateUserFlow, User, UserActionTypes } from "./types";

export const updateUser = (user: User | null): UserActionTypes => ({
  type: 'UPDATE_USER',
  payload: user,
});

export const setUpdateUserInputs = (payload: UpdateUserFlow): UserActionTypes => ({
  type: 'SET_UPDATE_USER_INPUTS',
  payload
})
