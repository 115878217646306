import { PayState, PayActionTypes } from "./types";

const initialState: PayState = {
  errors: {},
  selectedTransaction: null,
  loading: false
};

const payReducer = (state = initialState, action: PayActionTypes): PayState => {
  switch (action.type) {
    case 'SELECT_TRANSACTION':
      return {
        ...state,
        selectedTransaction: action.payload,
      };
    case 'SET_TRANSACTION_LOADING':
      return {
        ...state,
        loading: action.payload
      }
    default:
      return state;
  }
};

export default payReducer;
