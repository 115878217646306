import { RewardsState, RewardsActionTypes } from './types';

const initialState: RewardsState = {
  pointsSummary: {
    totalPoints: 0,
    redeemablePoints: 0,
    lifetimePointsEarned: 0,
    lifetimePointsSpent: 0,
  },
  redeemPointsFlow: {
    amount: 0,
    destinationWalletId: null,
  },
  referralFlow: {
    redemptionInput: '',
    redemptionInputError: '',
    redemptionInputMessage: '',
    isCheckingRedemptionInput: false,
    tagInput: '',
    tagInputError: '',
    tagInputMessage: '',
    isCheckingTagInput: false
  },
  pointsTransactions: [],
  pointsTransactionsDetails: {
    totalItems: 0,
    currentPage: 0,
    totalPages: 0,
  },
  isRedeemingPoints: false,
  isPointsLoading: false,
  pointsError: null,
  redeemError: null
};

const rewardsReducer = (state = initialState, action: RewardsActionTypes): RewardsState => {
  switch (action.type) {
    case 'UPDATE_POINTS_SUMMARY':
      return {
        ...state,
        pointsSummary: action.payload,
      };

    case 'UPDATE_REDEEM_POINTS_FLOW':
      return {
        ...state,
        redeemPointsFlow: action.payload,
      };

    case 'GET_USER_POINTS_BALANCE_SUCCESS':
      return {
        ...state,
        pointsSummary: action.payload,
        isPointsLoading: false,
        pointsError: null,
      };

    case 'GET_USER_POINTS_BALANCE_FAILURE':
      return {
        ...state,
        isPointsLoading: false,
        pointsError: action.payload,
      };

    case 'SET_USER_POINTS_LOADING':
      return {
        ...state,
        isPointsLoading: action.payload,
      };

    case 'REDEEM_POINTS_SUCCESS':
      return {
        ...state,
        isRedeemingPoints: false,
        redeemError: null,
      };

    case 'REDEEM_POINTS_FAILURE':
      return {
        ...state,
        isRedeemingPoints: false,
        redeemError: action.payload,
      };

    case 'SET_REDEEM_POINTS_LOADING':
      return {
        ...state,
        isRedeemingPoints: action.payload,
      };

    case 'GET_USER_POINTS_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        pointsTransactions: action.payload.transactions,
        pointsTransactionsDetails: action.payload.details,
        isPointsLoading: false,
        pointsError: null,
      };

    case 'GET_USER_POINTS_TRANSACTIONS_FAILURE':
      return {
        ...state,
        isPointsLoading: false,
        pointsError: action.payload,
      };

    case 'SET_POINTS_TRANSACTIONS_LOADING':
      return {
        ...state,
        isPointsLoading: action.payload,
      };
    case 'UPDATE_REFERRAL_FLOW':
      return {
        ...state,
        referralFlow: action.payload,
      };
    default:
      return state;
  }
};

export default rewardsReducer;
