import { BusinessItem } from "../../page/types";
import { Cart, CartItem } from "../types";


export const checkCartItems = (cart: Cart, itemReferences: BusinessItem[]) => {
  let newItems: CartItem[] = [];
  if (cart.items) {
    newItems = cart.items.map(cartItem => {
      let newItem = {...cartItem }
      newItem.data = {...cartItem.data, priceChange: false, previousPrice: cartItem.price} || {priceChange: false, previousPrice: cartItem.price};
      let inStock = true;
      const referenceItem = itemReferences.find(obj => obj._id === cartItem.itemId) || null;
      if (referenceItem) {
        inStock = referenceItem.pageData?.quantityOnHand && referenceItem.pageData.quantityOnHand >= cartItem.quantity || false;
        newItem.reference = referenceItem;
      }
      if (referenceItem && referenceItem.price !== cartItem.price) {
        newItem.data.priceChange = true;
        newItem.price = referenceItem.price;
      }
      newItem.selected = inStock; // deselect out of stock items
      newItem.data.inStock = inStock;
      return newItem;
    })
  }
  return {...cart, items: newItems};
}