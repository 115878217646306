import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import reduxThunk from 'redux-thunk';
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import "antd/dist/antd.css";
import './index.css';
import Router from "./router";
import i18n from "./translation";
import { ChakraProvider } from "@chakra-ui/react";
import store from './store/store';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const reduxStore = createStore(
        store,
        composeEnhancers(applyMiddleware(reduxThunk))
    );

const App = () => (
  <Provider store={reduxStore}>
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <ChakraProvider>
        <Router />
        </ChakraProvider>
      </I18nextProvider>
    </BrowserRouter>
  </Provider>
);

ReactDOM.render(<App />, document.getElementById("root"));
reportWebVitals();