import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { Cart } from '../types';
import { updateCartSuccess, updateCartFailure } from '../actions';
import { checkCartItems } from './checkCartItems';
import { fetchItemReferences } from './fetchItemReferences';

export const asyncAddItemToCart = (itemId: string, quantity: number, price: number, currency: string, businessLocationId: string, data?: any) => {
  return async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    try {
      const { auth } = getState();
      const { loginToken } = auth;
      const cartId = getState().cart.cart?._id;
      const { itemReferences } = getState().cart;

      if (!loginToken) {
        throw new Error('User not authenticated');
      }

      const headers = {
        'X-Auth-Token': loginToken,
      };

      const response = await backend.post(`/commerce-service/api/v1/carts/${cartId}/items?returnCart=1`, { cartId, itemId, quantity, price, currency, businessLocationId, data }, { headers });

      if (response.status === 201) {
        const addedItem: Cart = response.data;
        const newCart = checkCartItems(addedItem, itemReferences)
        dispatch(updateCartSuccess(newCart));
        dispatch(fetchItemReferences())
      } else {
        dispatch(updateCartFailure('Failed to add item to cart.'));
      }
    } catch (error) {
      console.error('Failed to add item to cart:', error);
      dispatch(updateCartFailure('An error occurred while adding item to cart.'));
    }
  };
};
