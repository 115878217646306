import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { Cart } from '../types';
import { updateCartSuccess, updateCartFailure } from '../actions';
import { checkCartItems } from './checkCartItems';

export const asyncUpdateItemInCart = (cartItemId: string, quantity: number, price: number, currency: string, data?: any) => {
  return async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    try {
      const { auth, cart } = getState();
      const { loginToken } = auth;
      const cartId = cart.cart?._id;
      const { itemReferences } = getState().cart;

      if (!loginToken) {
        throw new Error('User not authenticated');
      }

      const headers = {
        'X-Auth-Token': loginToken,
      };

      const response = await backend.put(`/commerce-service/api/v1/carts/${cartId}/items?returnCart=1`, { cartItemId, quantity, price, currency, data }, { headers });

      if (response.status === 200) {
        const updatedCart: Cart = response.data;
        const newCart = checkCartItems(updatedCart, itemReferences)
        dispatch(updateCartSuccess(newCart));
      } else {
        dispatch(updateCartFailure('Failed to update item in cart.'));
      }
    } catch (error) {
      console.error('Failed to update item in cart:', error);
      dispatch(updateCartFailure('An error occurred while updating item in cart.'));
    }
  };
};
