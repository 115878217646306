import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { Cart } from '../types';
import { createCartSuccess, createCartFailure } from '../actions';
import { checkCartItems } from './checkCartItems';

export const asyncCartCreate = () => {
  return async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    try {
      const { auth } = getState();
      const { user } = getState().user
      const { loginToken } = auth;
      const { itemReferences } = getState().cart;

      if (!loginToken || !user) {
        throw new Error('User information not available');
      }

      const userId = user._id;

      const headers = {
        'X-Auth-Token': loginToken,
      };

      const type = 'pages-ui';

      const response = await backend.post('/commerce-service/api/v1/carts', { userId, type }, { headers });

      if (response.status === 201 || response.status === 200) {
        const createdCart: Cart = response.data;
        const newCart = checkCartItems(createdCart, itemReferences)
        dispatch(createCartSuccess(newCart));
      } else {
        dispatch(createCartFailure('Failed to create a new cart.'));
      }
    } catch (error) {
      console.error('Failed to create a new cart:', error);
      dispatch(createCartFailure('An error occurred while creating a new cart.'));
    }
  };
};
