import { AccountState, AccountActionTypes } from './types';

const initialState: AccountState = {
  selectedView: 'showfa-summary'
};

const accountReducer = (state = initialState, action: AccountActionTypes): AccountState => {
  switch (action.type) {
    case 'SELECT_ACCOUNT_VIEW':
      return {
        ...state,
        selectedView: action.payload
      };
    default:
      return state;
  }
};

export default accountReducer;