import { PricingState, PricingActionTypes } from "./types";

const initialState: PricingState = {
  errors: {},
  pickupCoordinates: {
    latitude: null, longitude: null, address: ''
  },
  dropoffCoordinates: {
    latitude: null, longitude: null, address: ''
  },
  loading: false,
  locating: false,
  priceQuery: {
    distance: 0,
    deliveryFee: 0,
    deliveryType: 'out of range',
    branchCode: '',
    currency: '',
    schedule: {
      isActive: false,
    },
    pricing: {
      baseDeliveryRate: 0,
      baseDeliveryRangeKM: 0,
      perKMDeliveryRate: 0,
      finalDeliveryFeeRounding: 0,
      valueFeeThreshold: 0,
      valueFeePercentageRate: 0,
      taxes: [
        {
          taxName: '',
          taxThresholdMin: 0,
          taxThresholdMax: null
        }
      ]
    }
}
};

const pricingReducer = (state = initialState, action: PricingActionTypes): PricingState => {
  switch (action.type) {
    case 'SET_PICKUP_COORDINATES':
      return {
        ...state,
        pickupCoordinates: action.payload
      };
    case 'SET_DROPOFF_COORDINATES':
      return {
        ...state,
        dropoffCoordinates: action.payload
      };
    case 'SET_PRICE_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'SET_LOCATION_LOADING':
      return {
        ...state,
        locating: action.payload
      };
    case 'GET_PRICE_QUERY_SUCCESS':
      return {
        ...state,
        priceQuery: action.payload
      };    
    case 'GET_PRICE_QUERY_FAILURE':
      return {
        ...state,
        errors: { priceQuery: action.payload }
      };
    default:
      return state;
  }
};

export default pricingReducer;