import { Dispatch } from 'redux';

import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { clearAuthErrors, loginFailure, setRequestingCode } from '../actions';
import { updateUser } from '../../user/actions';

export const asyncRequestVerificationCode = (altMethod?: string) => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    try {
      const { phoneNumber, email } = getState().auth.loginFlow;
      dispatch(loginFailure(''))
      let url = `/api/v1/request-verification-code`
      if (altMethod) url += `?altMethod=${altMethod}`
      dispatch(clearAuthErrors());
      dispatch(setRequestingCode(true));
      let payload: any = { phoneNumber, app: 'web-ui' };
      if (altMethod === 'email') payload.email = email;
      const response = await backend.post(url, payload);
      if (response.status === 200) {
        console.log('Successfully requested verification code.');
        dispatch(setRequestingCode(false));
      } else {
        dispatch(loginFailure('Error requesting verification code. Try again.'));
        dispatch(updateUser(null));
        dispatch(setRequestingCode(false));
      }
    } catch (error) {
      console.error('Error requesting verification code. Try again.', error);
      dispatch(loginFailure('Error requesting verification code. Try again.'));
      dispatch(updateUser(null));
      dispatch(setRequestingCode(false));
    }
  };
};