import { OrdersState, OrdersActionTypes } from "./types";

const initialState: OrdersState = {
  errors: {},
  selectedOrder: null,
  selectedOrderTransactions: [],
  selectedOrderHistory: [],
  orders: [],
  loading: false,
  ordersQuery: {
    currentPage: null,
    totalItems: null,
    totalPages: null,
    itemsPerPage: null,
    search: null
  }
};

const ordersReducer = (state = initialState, action: OrdersActionTypes): OrdersState => {
  switch (action.type) {
    case 'GET_ORDERS_SUCCESS':
      return {
        ...state,
        orders: action.payload
      };
    case 'GET_ORDERS_FAILURE':
      return {
        ...state,
        errors: { getOrders: action.payload },
      };
    case 'SELECT_ORDER':
      return {
        ...state,
        selectedOrder: action.payload,
      };
    case 'GET_SELECTED_ORDER_TRANSACTIONS_SUCCESS':
      return {
        ...state,
        selectedOrderTransactions: action.payload
      };
    case 'GET_SELECTED_ORDER_TRANSACTIONS_FAILURE':
      return {
        ...state,
        errors: { editSelectedOrderTransactions: action.payload }
      };
    case 'EDIT_SELECTED_ORDER_SUCCESS':
      return {
        ...state,
        selectedOrder: action.payload,
      };
    case 'EDIT_SELECTED_ORDER_FAILURE':
      return {
        ...state,
        errors: { editSelectedOrder: action.payload }
      };
    case 'UPDATE_ORDERS_QUERY':
      return {
        ...state,
        ordersQuery: action.payload
      };
    case 'TOGGLE_ORDERS_LOADING':
      return {
        ...state,
        loading: action.payload
      };
    case 'GET_ORDER_HISTORY_SUCCESS':
      return {
        ...state,
        selectedOrderHistory: action.payload,
      };
    case 'GET_ORDER_HISTORY_FAILURE':
      return {
        ...state,
        errors: { getOrderHistory: action.payload },
      };
    default:
      return state;
  }
};

export default ordersReducer;
