import { Dispatch } from 'redux';
import { ReduxState } from '../../state';

import backend from '../../../apis/backend';
import { getActiveLoginsFailure, getActiveLoginsSuccess, setGettingActiveLogins } from '../actions';

export const asyncCheckLogins = () => {
  return async (dispatch: Dispatch | any, getState: () => ReduxState) => {
    try {
      const { auth, user } = getState();
      const loginToken = auth.loginToken;
      const userId = user.user?._id
    
      const headers = {
        'X-Auth-Token': loginToken,
      };
      dispatch(setGettingActiveLogins(true));
      const response = await backend.post('/api/v1/check-user-logins', { userId }, { headers });
      if (response.status === 200) {
        dispatch(getActiveLoginsSuccess(response.data));
        dispatch(setGettingActiveLogins(false));
      } else {
        dispatch(getActiveLoginsFailure('Failed to get active logins'));
        dispatch(setGettingActiveLogins(false));
      }
    } catch (error) {
      console.error('Failed to get active logins:', error);
      dispatch(getActiveLoginsFailure('Failed to get active logins'));
      dispatch(setGettingActiveLogins(false));
    }

  };
};