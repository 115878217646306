import { Dispatch } from 'redux';
import axios from 'axios';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { setRequestingCode, setUpdateEmailInputs } from '../actions';
import { updateUser } from '../../user/actions';

export const asyncRequestEmailVerification = () => {
  return async (dispatch: Dispatch, getState: () => ReduxState) => {
    const { updateEmailFlow } = getState().auth;
    try {
      const { user } = getState().user;
      const { email } = updateEmailFlow;
      dispatch(setUpdateEmailInputs({...updateEmailFlow, errors: {}}))
      let url = `/api/v1/request-email-verification`
      dispatch(setRequestingCode(true));
      let payload: any = { email, app: 'web-ui', userId: user?._id };
      const response = await backend.post(url, payload);
      if (response.status === 200) {
        console.log('Successfully requested email verification');
        dispatch(setUpdateEmailInputs({...updateEmailFlow, codeRequested: true, errors: null }))
        dispatch(setRequestingCode(false));
      } else if (response.status === 409) {
        dispatch(setUpdateEmailInputs({...updateEmailFlow, errors: { updateEmail: response.data.message }}))
        dispatch(setRequestingCode(false));
      } else {
        dispatch(setUpdateEmailInputs({...updateEmailFlow, errors: { updateEmail: 'Error requesting verification code. Try again later.' }}))
        dispatch(setRequestingCode(false));
      }
    } catch (error: any) {
      // Handle error response
      if (axios.isAxiosError(error) && error.response) {
        // Check for 404 status
        if (error.response.status === 409) {
          dispatch(setUpdateEmailInputs({...updateEmailFlow, errors: { updateEmail: error.response.data.message }}))
          dispatch(setRequestingCode(false));
        } else {
          dispatch(setUpdateEmailInputs({...updateEmailFlow, errors: { updateEmail: 'Error requesting verification code. Try again later.' }}))
          dispatch(setRequestingCode(false));
        }
      } else {
        // Catch other errors
        dispatch(setUpdateEmailInputs({...updateEmailFlow, errors: { updateEmail: 'Error requesting verification code. Try again later.' }}))
        dispatch(setRequestingCode(false));
      }

      dispatch(setRequestingCode(false));
    }
  };
};