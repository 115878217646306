import { combineReducers } from "redux";
import authReducer from "./auth/reducers";
import payReducer from './pay/reducers';
import pricingReducer from "./pricing/reducers";
import userReducer from "./user/reducers";
import accountReducer from "./account/reducers";
import rewardsReducer from "./rewards/reducers";
import walletsReducer from "./wallets/reducers";
import ordersReducer from "./orders/reducers";
import pageReducer from "./page/reducers";
import locationReducer from "./location/reducers";
import eventReducer from "./events/reducers";
import cartReducer from "./cart/reducers";

export default combineReducers({
  auth: authReducer,
  pay: payReducer,
  pricing: pricingReducer,
  user: userReducer,
  account: accountReducer,
  rewards: rewardsReducer,
  wallets: walletsReducer,
  orders: ordersReducer,
  page: pageReducer,
  location: locationReducer,
  events: eventReducer,
  cart: cartReducer
})