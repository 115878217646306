import { BusinessItem, PageOrderFlow } from '../page/types';
import { CartActionTypes, Cart, CartItem, SubmittedOrder } from './types';

export const createCartSuccess = (cart: Cart): CartActionTypes => ({
  type: 'CREATE_CART_SUCCESS',
  payload: cart,
});

export const createCartFailure = (error: string): CartActionTypes => ({
  type: 'CREATE_CART_FAILURE',
  payload: error,
});

export const updateCartSuccess = (cart: Cart): CartActionTypes => ({
  type: 'UPDATE_CART_SUCCESS',
  payload: cart,
});

export const updateCartFailure = (error: string): CartActionTypes => ({
  type: 'UPDATE_CART_FAILURE',
  payload: error,
});

export const getItemReferencesSuccess = (itemReferences: BusinessItem[]): CartActionTypes => ({
  type: 'GET_ITEM_REFERENCES_SUCCESS',
  payload: itemReferences,
});

export const getItemReferencesFailure = (error: string): CartActionTypes => ({
  type: 'GET_ITEM_REFERENCES_FAILURE',
  payload: error,
});

export const setOrdersCalculating = (payload: boolean): CartActionTypes => ({
  type: 'SET_ORDERS_CALCULATING',
  payload
})

export const updateOrdersFlow = (payload: PageOrderFlow[]): CartActionTypes => ({
  type: 'UPDATE_ORDER_FLOWS',
  payload
})

export const setOrdersSubmitting = (payload: boolean): CartActionTypes => ({
  type: 'SET_ORDERS_SUBMITTING',
  payload
})

export const updateSubmittedOrders = (payload: SubmittedOrder[]): CartActionTypes => ({
  type: 'UPDATE_SUBMITTED_ORDERS',
  payload
})