import { Dispatch } from 'redux';
import { ReduxState } from '../../state';

import backend from '../../../apis/backend';
import { getUserEventsFailure, getUserEventsSuccess, setGettingUserEvents } from '../actions';

export const fetchUserEvents = () => {
  return async (dispatch: Dispatch | any, getState: () => ReduxState) => {
    try {
      const { auth, user } = getState();
      const loginToken = auth.loginToken;
      const userId = user.user?._id
    
      const headers = {
        'X-Auth-Token': loginToken,
      };
      dispatch(setGettingUserEvents(true));
      const response = await backend.get(`/api/v1/user-activity-logs?userId=${userId}&sortBy=timestamp&sortDirection=desc`, { headers });
      if (response.status === 200) {
        dispatch(getUserEventsSuccess(response.data.userActivityLogs));
        dispatch(setGettingUserEvents(false));
      } else {
        dispatch(getUserEventsFailure('Failed to get user events'));
        dispatch(setGettingUserEvents(false));
      }
    } catch (error) {
      console.error('Failed to get user events:', error);
      dispatch(getUserEventsFailure('Failed to get user events'));
      dispatch(setGettingUserEvents(false));
    }

  };
};