import { UserState, UserActionTypes } from './types';

const initialState: UserState = {
  errors: {},
  user: null,
  updateUserFlow: {
    firstName: '',
    lastName: '',
    errors: null
  }
};

const userReducer = (state = initialState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case 'UPDATE_USER':
      return {
        ...state,
        user: action.payload
      };
    case 'SET_UPDATE_USER_INPUTS':
      return {
        ...state,
        updateUserFlow: action.payload
      }
    default:
      return state;
  }
};

export default userReducer;