import { CartState, CartActionTypes } from './types';

const initialState: CartState = {
  errors: {},
  cart: null,
  itemReferences: [],
  orderFlows: [],
  submittedOrders: [],
  isCalculating: false,
  isSubmitting: false
};

const cartReducer = (state = initialState, action: CartActionTypes): CartState => {
  switch (action.type) {
    case 'CREATE_CART_SUCCESS':
    case 'UPDATE_CART_SUCCESS':
      return {
        ...state,
        cart: action.payload,
        errors: {},
      };
    case 'CREATE_CART_FAILURE':
    case 'UPDATE_CART_FAILURE':
      return {
        ...state,
        errors: { cart: action.payload },
      };
    case 'GET_ITEM_REFERENCES_SUCCESS':
      return {
        ...state,
        itemReferences: action.payload,
        errors: {},
      };
    case 'GET_ITEM_REFERENCES_FAILURE':
      return {
        ...state,
        errors: { itemReferences: action.payload },
      };
    case 'SET_ORDERS_CALCULATING':
      return {
        ...state,
        isCalculating: action.payload
      };
    case 'UPDATE_ORDER_FLOWS':
      return {
        ...state,
        orderFlows: action.payload
      };
    case 'UPDATE_SUBMITTED_ORDERS':
      return {
        ...state,
        submittedOrders: action.payload
      };
    case 'SET_ORDERS_SUBMITTING':
      return {
        ...state,
        isSubmitting: action.payload
      }
    default:
      return state;
  }
};

export default cartReducer;
