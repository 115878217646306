import { Dispatch } from 'redux';
import backend from '../../../apis/backend';
import { ReduxState } from '../../state';
import { getItemReferencesSuccess, getItemReferencesFailure, updateCartSuccess } from '../actions';
import { checkCartItems } from './checkCartItems';

// Thunk action to fetch page
export const fetchItemReferences = () => {
  return async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    try {
      const { auth, cart } = getState();
      const { loginToken } = auth;

      if (!loginToken || !cart.cart) {
        throw new Error('User not authenticated');
      }

      const itemIds = cart.cart?.items?.map(ref => ref.itemId)

      const headers = {
        'X-Auth-Token': loginToken,
      };
      const response = await backend.post(`/marketplace-service/api/v1/retrieve-many-items`, { itemIds }, { headers });

      if (response.status === 200) {
        dispatch(getItemReferencesSuccess(response.data));   
        const newCart = checkCartItems(cart.cart, response.data)
        dispatch(updateCartSuccess(newCart)); 
      } else {
        dispatch(getItemReferencesFailure('Error getting item reference'));
      }
    } catch (error) {
      dispatch(getItemReferencesFailure('Error getting item reference'));
    }
  };
};