import { Dispatch } from 'redux';
import { ReduxState } from '../../state';

import backend from '../../../apis/backend';
import { logout, logoutFailure } from '../actions';
import { asyncCheckLogins } from './asyncCheckLogins';
import { fetchUserEvents } from './fetchUserEvents';

export const asyncLogout = (tokenId: string, userId: string, bulkLogout: boolean = false) => {
  return async (dispatch: Dispatch<any>, getState: () => ReduxState) => {
    const { auth } = getState();
    const { loginToken } = auth;
    let altLogout = false;
    let url = `/api/v1/logout`
    let payload: any = {
      tokenId,
      userId,
      app: 'bridge-ui'
    }

    if (tokenId !== loginToken) {
      altLogout = true;
    }

    if (bulkLogout) {
      url = '/api/v1/bulk-logout';
      payload = {
        userId,
        exceptionTokenId: tokenId
      }
    }

    try {
      const response = await backend.post(url, payload);

      if (response.status === 200 && !altLogout && !bulkLogout) { //user logged out of current session, clear states
          localStorage.removeItem('showfaWebUiLoginToken');
          localStorage.removeItem('showfaWebUiUser');
          dispatch(logout());
      } else if (response.status === 200) { //user logged out of other session(s), refresh active logins
        dispatch(asyncCheckLogins());
        dispatch(fetchUserEvents())
      } else {
        dispatch(logoutFailure('Logout failed'));
      }
    } catch (error) {
      console.error('Error during logout:', error);
      dispatch(logoutFailure('An error occurred during logout.'));
    }
  };
};