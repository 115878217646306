import { 
  BusinessItem,
  OrderItem,
  PageActionTypes,
  PageOrderFlow,
  Order,
  Transaction,
  SoldOutEstimate,
  ItemsQuery
} from './types';

// SELECT PAGE

export const setPageLoading = (payload: boolean): PageActionTypes => ({
  type: 'SET_PAGE_LOADING',
  payload
});

// Action to select a specific page
export const selectPage = (page: BusinessItem | null): PageActionTypes => ({
  type: 'SELECT_PAGE',
  payload: page,
});

export const updatePageOrderInputs = (payload: PageOrderFlow): PageActionTypes => ({
  type: 'UPDATE_PAGE_ORDER_INPUTS',
  payload,
});

export const selectOrderItem = (payload: OrderItem | null): PageActionTypes => ({
  type: 'SELECT_ORDER_ITEM',
  payload,
});

export const updateSelectedOrderItem = (payload: OrderItem): PageActionTypes => ({
  type: 'UPDATE_ORDER_ITEM',
  payload,
});

// Action to create an order successfully
export const createOrderSuccess = (order: Order): PageActionTypes => ({
  type: 'CREATE_ORDER_SUCCESS',
  payload: order,
});

// Action to handle failed order creation
export const createOrderFailure = (error: string): PageActionTypes => ({
  type: 'CREATE_ORDER_FAILURE',
  payload: error,
});

// Action to fetch order transactions successfully
export const getOrderTransactionsSuccess = (transactions: Transaction[]): PageActionTypes => ({
  type: 'GET_ORDER_TRANSACTIONS_SUCCESS',
  payload: transactions,
});

// Action to handle failed order transaction fetch
export const getOrderTransactionsFailure = (error: string): PageActionTypes => ({
  type: 'GET_ORDER_TRANSACTIONS_FAILURE',
  payload: error,
});

// Action to fetch other items from business successfully
export const getOtherItemsFromBusinessSuccess = (items: BusinessItem[]): PageActionTypes => ({
  type: 'GET_OTHER_ITEMS_FROM_BUSINESS_SUCCESS',
  payload: items,
});

// Action to handle failed fetching of other items from business
export const getOtherItemsFromBusinessFailure = (error: string): PageActionTypes => ({
  type: 'GET_OTHER_ITEMS_FROM_BUSINESS_FAILURE',
  payload: error,
});

// Action to fetch previously viewed items successfully
export const getPreviouslyViewedItemsSuccess = (items: BusinessItem[]): PageActionTypes => ({
  type: 'GET_PREVIOUSLY_VIEWED_ITEMS_SUCCESS',
  payload: items,
});

// Action to handle failed fetching of previously viewed items
export const getPreviouslyViewedItemsFailure = (error: string): PageActionTypes => ({
  type: 'GET_PREVIOUSLY_VIEWED_ITEMS_FAILURE',
  payload: error,
});

// Action to fetch previously purchased items successfully
export const getPreviouslyPurchasedItemsSuccess = (items: BusinessItem[]): PageActionTypes => ({
  type: 'GET_PREVIOUSLY_PURCHASED_ITEMS_SUCCESS',
  payload: items,
});

// Action to handle failed fetching of previously purchased items
export const getPreviouslyPurchasedItemsFailure = (error: string): PageActionTypes => ({
  type: 'GET_PREVIOUSLY_PURCHASED_ITEMS_FAILURE',
  payload: error,
});

// Action to fetch trending items successfully
export const getTrendingItemsSuccess = (items: BusinessItem[]): PageActionTypes => ({
  type: 'GET_TRENDING_ITEMS_SUCCESS',
  payload: items,
});

// Action to handle failed fetching of trending items
export const getTrendingItemsFailure = (error: string): PageActionTypes => ({
  type: 'GET_TRENDING_ITEMS_FAILURE',
  payload: error,
});

// Action to fetch trending items successfully
export const getItemSoldOutEstimateSuccess = (payload: SoldOutEstimate): PageActionTypes => ({
  type: 'GET_ITEM_SOLD_OUT_ESTIMATE_SUCCESS',
  payload,
});

// Action to handle failed fetching of trending items
export const getItemSoldOutEstimateFailure = (error: string): PageActionTypes => ({
  type: 'GET_ITEM_SOLD_OUT_ESTIMATE_FAILURE',
  payload: error,
});

// Action to update items query
export const updateItemsQuery = (payload: ItemsQuery): PageActionTypes => ({
  type: 'UPDATE_ITEMS_QUERY',
  payload,
});

// Action to update items query
export const getQueriedItemsSuccess = (payload: BusinessItem[]): PageActionTypes => ({
  type: 'GET_QUERIED_ITEMS_SUCCESS',
  payload,
});

// Action to update items query
export const getQueriedItemsFailure = (payload: string): PageActionTypes => ({
  type: 'GET_QUERIED_ITEMS_FAILURE',
  payload,
});

// Action to update items query
export const setItemsQuerying = (payload: boolean): PageActionTypes => ({
  type: 'SET_ITEMS_QUERYING',
  payload,
});